import styled from "styled-components";
import {
    ManageAreaContent,
    ManageAreaContentFrequency,
    ManageAreaContentFrequencyButtons,
    ManageAreaHeader,
    ManageAreaHeaderImage,
    ManageAreaHeaderText,
    ManageAreaWrapper,
} from "../ManageArea/manageAreaStyled";

export const CleaningFormWrapper = styled(ManageAreaWrapper)``;
export const CleaningFormHeader = styled(ManageAreaHeader)``;
export const CleaningFormHeaderImage = styled(ManageAreaHeaderImage)`
  margin-bottom: 15px;
`;
export const CleaningFormHeaderText = styled(ManageAreaHeaderText)``;
export const CleaningFormHeaderTitle = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 7px;
`;

export const CleaningFormContent = styled(ManageAreaContent)``;
export const CleaningFormContentFrequency = styled(
    ManageAreaContentFrequency
)`
    flex-direction: row;
    margin-bottom: 12px;
`;
export const CleaningFormContentFrequencyButtons = styled(
    ManageAreaContentFrequencyButtons
)``;
export const CleaningFormContentTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4f4f4f;
  margin-bottom: 8px;
`;
export const CleaningFormContentWrapper = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 26px;
`;

export const CleaningFormContentMetersBox = styled.div`
    width: 100%;
    margin-top: 8px;
    label {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #4F4F4F;
    }
`;
export const CleaningFormContentMetersInput = styled.input`
    background: ${({ max, min, value }: { [key: string]: number | any }) => {
        return `linear-gradient(to right, #5CC297 0%, #5CC297 ${(value - min) / (max - min) * 100}%, #D7D7D7 ${(value - min) / (max - min) * 100}%, #D7D7D7 100%)`
    }};
    border: solid 1px #82CFD0;
    border-radius: 8px;
    height: 5px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        background-color: white;
        border-radius: 50%;
        border: 3px solid #5CC297;
    }
`
export const CleaningFormContentTime = styled.div`
`
export const CleaningFormContentTimeButton = styled.div`
    display: inline-block;
    padding: 10px 18px;
    background-color: #5CC297;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 4px;
    position: relative;

    input {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {        
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;    
     }
`


export const CleaningFormContentFloorButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const CleaningFormContentButton = styled.button`
    padding: 10px 20px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #4F4F4F;
    margin: 2px;
    &.active {
        background: #F4A710;
        color: white;
    }
`


export const CleaningFormContentWindows = styled.div`
    display: flex;
    flex-wrap: wrap;
`


export const CleaningFormContentFile = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 3px;
    border: 0.753886px solid #BDBDBD;
    border-radius: 3.01554px;
    width: 100%;
    overflow: hidden;
    label {
        margin-left: 15px;
    }
    button {
        padding: 10px 19px;
        background: #5CC297;
        border-radius: 3.01554px;
        font-weight: bold;
        font-size: 11px;
        line-height: 15px;
        color: white;
        border: none
    }
    input {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
    }
`

export const CleaningFormContentComment = styled.textarea`
    width: 100%;
    min-height: 200px;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
`

export const CleaningFormContentButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    button {
        max-width: 140px;
    }
`