import styled from "styled-components";
import cleanSvg from '../../assets/svg/clean.svg'

export const PreviewWrapper = styled.div`
    position: absolute;
    background: white;
    top: 56px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
`
export const PreviewHeader = styled.div`
    background: #1A7EDA;
    width: 100%;
    height: 110px;
    background-image: url(${cleanSvg});
    background-repeat: no-repeat;
    background-position: -1px 21px;
`

export const PreviewInner = styled.div`
    padding: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    @media only screen and (min-width: 768px) {
        max-width: 60%;
        margin: 0 auto;
    }
`
export const PreviewTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #4F4F4F;
    margin-bottom: 28px;
`

export const PreviewMainInformation = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4F4F4F;
    margin-bottom: 20px;
`

export const PreviewRoomInformation = styled.div`
    align-self: flex-start;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4F4F4F;
    margin-bottom: 20px;
    span {
        font-weight: 500;
        margin: 0 2px;
    }
`

export const PreviewButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    gap: 20px;
    button {
        width: 170px;
    }
`
