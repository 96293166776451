import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ProgressBar } from '..'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { deleteBooking, fetchBookings, selectedOneTime } from '../../redux/bookingsReducer'
import { Button, ButtonWrapper, Title } from '../../style'
import { BookingImage, BookingsItem, BookingsItemContent, BookingsItemContentIconChange, BookingsItemContentIconDelete, BookingsItemContentIcons, BookingsItemContentInfo, BookingsItemContentInfoText, BookingsItemHeader, BookingsItemHeaderStatus, BookingsItems, BookingsModal, BookingsModalButton, BookingsModalButtons, BookingsModalInner, BookingsModalText, BookingsWrapper, BookingText } from './bookingsStyled'

export const Bookings = () => {
    const { id } = useAppSelector(({ user }) => user)
    const { text } = useAppSelector(({ language }) => language)
    const { data, isLoading } = useAppSelector(({ bookings }) => bookings)
    const [stateModal, setStateModal] = useState({
        open: false,
        id: ''
    })
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (id) {
            dispatch(fetchBookings(id))
        }
    }, [id, dispatch])

    const deleteBookingHandler = (id: string) => {
        setStateModal(prev => ({...prev, id, open: true}))
    }

    const bookHandlerClick = (one_time: boolean) => {
        dispatch(selectedOneTime(one_time))
        navigate('/manage')
    }

    if (isLoading) {
        return <ProgressBar />
    }
    if (!id) {
        return (
            <BookingsWrapper>
                <BookingImage />
                <BookingText>{text.booking_empty.split(' ').map(word => {
                    if(word === 'selecting') {
                        return <>{word}<br/></>
                    }
                    return `${word} `
                })}</BookingText>
                <ButtonWrapper>
                    <Button style={{ marginBottom: 8 }} onClick={() => bookHandlerClick(true)}>{text.button_one_time_cleaning}</Button>
                    <Button onClick={() => bookHandlerClick(false)}>{text.button_contractual_cleaning}</Button>
                </ButtonWrapper>
            </BookingsWrapper>
        )
    }
    if (!data.length) {
        return (
            <BookingsWrapper>
                <BookingImage />
                <BookingText>{text.booking_empty.split(' ').map(word => {
                    if(word === 'selecting') {
                        return <>{word}<br/></>
                    }
                    return `${word} `
                })}</BookingText>
                <ButtonWrapper>
                    <Button style={{ marginBottom: 8 }} onClick={() => bookHandlerClick(true)}>{text.button_one_time_cleaning}</Button>
                    <Button onClick={() => bookHandlerClick(false)}>{text.button_contractual_cleaning}</Button>
                </ButtonWrapper>
            </BookingsWrapper>
        )
    }
    return (
        <BookingsWrapper>
            <Title>{text.your_bookings}</Title>
            <BookingsItems>
                {stateModal.open &&
                    <BookingsModal onClick={() => setStateModal({id: '', open: false})}>
                        <BookingsModalInner onClick={(e) => e.stopPropagation()}>
                            <BookingsModalText>{text.modal_delete_text}</BookingsModalText>
                            <BookingsModalButtons>
                                <BookingsModalButton onClick={() => setStateModal({id: '', open: false})} >{text.button_cancel}</BookingsModalButton>
                                <BookingsModalButton onClick={() => {dispatch(deleteBooking(stateModal.id)); setStateModal({id: '', open: false})}} style={{backgroundColor: "#DB424B", borderColor: "red"}} >{text.button_delete}</BookingsModalButton>
                            </BookingsModalButtons>
                        </BookingsModalInner>
                    </BookingsModal>}
                {data.map(booking => {
                    return (
                        <BookingsItem key={booking._id}>
                            <BookingsItemHeader>
                                <BookingsItemHeaderStatus>{text.active}</BookingsItemHeaderStatus>
                            </BookingsItemHeader>
                            <BookingsItemContent>
                                <BookingsItemContentInfo>
                                    <BookingsItemContentInfoText>{text.created_booking} {new Date(booking.main.date).toLocaleDateString()}</BookingsItemContentInfoText>
                                    <BookingsItemContentInfoText>
                                        {booking.main.one_time ?
                                        `${text.cleaning_date} ${new Date(booking.main.cleaning_date).toLocaleDateString()}` :
                                         `${text['Frequency:']} ${
                                             //@ts-ignore
                                        text.frequency[booking.main.activeFrequency]}`}</BookingsItemContentInfoText>
                                    <BookingsItemContentInfoText>{text.cleaning_time} {booking.main.cleaning_time || 'Empty'}</BookingsItemContentInfoText>
                                </BookingsItemContentInfo>
                                <BookingsItemContentIcons>
                                    <BookingsItemContentIconDelete onClick={() => deleteBookingHandler(booking._id)} />
                                    <BookingsItemContentIconChange onClick={() => navigate(`/manage/${booking._id}`)} />
                                </BookingsItemContentIcons>
                            </BookingsItemContent>
                        </BookingsItem>
                    )
                })}
            </BookingsItems>
            <BookingText>{text.booking_more} </BookingText>
            <ButtonWrapper>
                <Button style={{ marginBottom: 8 }} onClick={() => bookHandlerClick(true)}>{text.button_one_time_cleaning}</Button>
                <Button onClick={() => bookHandlerClick(false)}>{text.button_contractual_cleaning}</Button>
            </ButtonWrapper>
            <br />
        </BookingsWrapper>
    )
}
