import styled from "styled-components";
import cleanSvg from '../../assets/svg/clean.svg'



export const TopContentWrapper = styled.div`
    height: 110px;
    width: 100%;
    background-color: #1A7EDA;
    background-image: url(${cleanSvg});
    background-repeat: no-repeat;
    background-position: -10px 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const TopContentTitle = styled.h2`
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 5px;
`
export const TopContentText = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
`
export const TopContentInner = styled.div`
    text-align: center;
    word-wrap: break-word;
    margin-left: 90px;
    width: 55%;

    @media only screen and (min-width: 768px) {
        margin-left: 0;
    }
`