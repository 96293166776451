import React from 'react'
import { BasicForm, ProgressBar } from '../components'
import { useAppSelector } from '../hooks/redux'

export const BasicInfoPage = () => {
    const {isLoading, } = useAppSelector(({fillBaseInfo}) => fillBaseInfo)

    if(isLoading) {
       return <ProgressBar />
    }

    return (
        <>
            <BasicForm />
        </>
    )
}
