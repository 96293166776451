import styled from "styled-components";

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 8px;
  background-color: #41aefe;
  z-index: 1000;
  position: absolute;
  top: 56px;
  overflow: hidden;
  right: 0;
  left: 0;
`;

export const ProgressBarInner = styled.div`
  width: 0%;
  height: 100%;
  background-color: #005ba4;
  animation: animate 1.5s infinite;
  @keyframes animate {
    0% {
      transform: translateX(-100%);
      width: 10%;
    }
    50% {
      transform: translateX(900%);
      width: 300%;

    }
    100% {
      transform: translateX(10000%);
      width: 10%;
    }
  }
`;
