
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import data from '../language/fi.json'

const initialState = {
    lang: "fi",
    text: data
}

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        initLanguageAction: (state) => {
            state.lang = localStorage.getItem("language") || initialState.lang
            state.text = require(`../language/${localStorage.getItem("language") || initialState.lang}.json`)
        },
        changeLanguageAction: (state, {payload}: PayloadAction<string>) => {
            localStorage.setItem("language", payload)
            state.lang = payload
            state.text = require(`../language/${payload}.json`)
        }
    }
})


export const {changeLanguageAction, initLanguageAction} = languageSlice.actions
export default languageSlice.reducer