import styled from "styled-components";


export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const LoginFormInputBox = styled.div`
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    img {
        position: absolute;
        width: 24px;
        right: 10px;
        bottom: 14px;
    }

    @media only screen and (min-width: 768px) {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
`
export const LoginFormInput = styled.input<LoginFormInputType>`
    width: 100%;
    padding: 16px 24px;
    border: 1px solid ${({error}) => error ? 'red' : '#BDBDBD'};
    border-radius: 4px;
    outline: none;
    &:focus {
        box-shadow: 0px 0px 3px black;
    }
`
export const LoginFormError = styled.div`
    width: 100%;
    text-align: center;
    color: red;
    position: absolute;
`
export const LoginRegistrationLink = styled.div`
    text-align: center;
    margin: 24px 0;
    a {
        color: #1E57F1;
    }
    
`

type LoginFormInputType = {
    error: boolean
}