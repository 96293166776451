import React from 'react'
import { ProgressBarInner, ProgressBarWrapper } from './progressBarStyled'

export const ProgressBar = () => {
    return (
        <ProgressBarWrapper>
            <ProgressBarInner />
        </ProgressBarWrapper>
    )
}
