import styled from "styled-components";


type ButtonType = {
    outline?: boolean
}
export const Button = styled.button<ButtonType>`
    width: 238.85px;
    height: 55px;
    background:  ${({disabled, outline}) => disabled ? "#BDBDBD" : outline ? "transparent" :"#1A7EDA" };
    border-radius: 6.95px;
    color: ${({outline}) => outline ? "#333333" : "white"};
    border: none;
    font-weight: bold;
    cursor: pointer;
    border: 2px solid #1A7EDA;
    position: relative;
    overflow: hidden;
    font-size: 1em;

    @media only screen and (min-width: 768px) {
        &:last-child {
            margin-left: 14px;
        }
    }
`

export const ButtonWrapper = styled.div`
    text-align: center;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 28px 8px;
    background: #FFFFFF;
    box-shadow: 0px 6.94845px 34.7423px rgba(9, 44, 76, 0.16);
    border-radius: 6.94845px;
    margin-bottom: 16px;
`

export const Title = styled.h2`
    font-family: Avenir;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #333333;
    text-align: center;
    margin-bottom: 23px;
`


type InputType = {
    error?: boolean
}
export const Input = styled.input<InputType>`
    width: 100%;
    padding: 16px 24px;
    border: 1px solid ${({error}) => error ? 'red' : '#BDBDBD'};
    border-radius: 4px;
    outline: none;
    margin-bottom: 12px;
    &:focus {
        box-shadow: 0px 0px 3px black;
    }
`