import styled from "styled-components";




export const BasicFormWrapper = styled.div``
export const BasicFormForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;

    @media only screen and (min-width: 768px) {
        width: 55%;
        margin: 0 auto;
    }
`
export const BasicFormNote = styled.p`
    text-align: center;
    margin-bottom: 15px;
`