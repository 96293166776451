import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import $api from "../http/api";

type fillBaseUserState = {
  fillBase: {
    [key: string]: string | number | boolean;
  };
  isLoading: boolean;
  error: string;
};

const initialState: fillBaseUserState = {
  fillBase: {},
  isLoading: true,
  error: "",
};

export const fetchFillBase = createAsyncThunk(
  "fillBaseUser/fetchFillBase",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await $api.get<fillBaseUserState>(`/fill-base/${id}`);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateFillBase = createAsyncThunk("fillBaseUser/updateFillBase", async ({id, fillBase}: {id: string, fillBase: any}, {rejectWithValue}) => {
    try {
        const {data} = await $api.put<fillBaseUserState>('/fill-base/update', {
            id,
            fillBase
        })
        return data
    } catch (error: any) {
        return rejectWithValue(error.response.data.message)
    }
})

const fillBaseUserSlice = createSlice({
  name: "fillBaseUser",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFillBase.pending.type]: (state) => {
        state.isLoading = true
    },
    [fetchFillBase.fulfilled.type]: (state, {payload}: PayloadAction<fillBaseUserState>) => {
        state.isLoading = false
        state.fillBase = payload.fillBase
        state.error = ''        
    },
    [fetchFillBase.rejected.type]: (state, {payload}: PayloadAction<string>) => {
        state.fillBase = initialState.fillBase
        state.isLoading = false
        state.error = payload
    },
    [updateFillBase.fulfilled.type]: (state, {payload}: PayloadAction<fillBaseUserState>) => {
        state.isLoading = false
        state.fillBase = payload.fillBase
        state.error = ''        
    },
  },
});

export default fillBaseUserSlice.reducer;
