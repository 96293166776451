import React, { useEffect, useRef, useState } from 'react'
import { DateTimeWrapper, DateWrapper, FrequencyButtonsWrapper, ManageAreaButtons, ManageAreaContent, ManageAreaContentFrequency, ManageAreaContentFrequencyButtons, ManageAreaContentInfo, ManageAreaContentItem, ManageAreaContentItemImage, ManageAreaContentItems, ManageAreaContentItemsBox, ManageAreaContentItemText, ManageAreaHeader, ManageAreaHeaderImage, ManageAreaHeaderText, ManageAreaWrapper, TimePickerWrapper } from './manageAreaStyled'
import dressingRoomsSvg from '../../assets/svg/manageIcons/dressingrooms.svg'
import entranceHallSvg from '../../assets/svg/manageIcons/entrancehall.svg'
import hallwaySvg from '../../assets/svg/manageIcons/hallway.svg'
import kitchenSvg from '../../assets/svg/manageIcons/kitchen.svg'
import officeSvg from '../../assets/svg/manageIcons/office.svg'
import storageSvg from '../../assets/svg/manageIcons/storage.svg'
import wcSVG from '../../assets/svg/manageIcons/wc.svg'
import meetingRoomsSvg from '../../assets/svg/manageIcons/meetingrooms.svg'
import useLongPress from '../../hooks/useLongPress'
import { useNavigate, useParams } from 'react-router'
import { Button, Input, Title } from '../../style'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { changeRooms, clearCurrentBooking, getLocalState, updateCurrentBooking } from '../../redux/bookingsReducer'
import { ProgressBar, TopContent } from '..'
import { CleaningFormContentTimeButton } from '../CleaningForm/cleaningFormStyled'
import { Booking } from '../../interfaces/bookings'
const MANAGE_AREA_DATA = [
    {
        id: 1,
        img: hallwaySvg,
        text: 'Hallway'
    },
    {
        id: 2,
        img: entranceHallSvg,
        text: 'Entrance hall'
    },
    {
        id: 3,
        img: officeSvg,
        text: 'Office'
    },
    {
        id: 4,
        img: kitchenSvg,
        text: 'Kitchen'
    },
    {
        id: 5,
        img: meetingRoomsSvg,
        text: 'Meeting rooms'
    },
    {
        id: 6,
        img: wcSVG,
        text: 'WC'
    },
    {
        id: 7,
        img: storageSvg,
        text: 'Storage'
    },
    {
        id: 8,
        img: dressingRoomsSvg,
        text: 'Dressing rooms'
    }
]
export const FREQUENCY_DATA = [
    {
        id: 1,
        text: "Weekly"
    },
    {
        id: 2,
        text: "Monthly"
    },
    {
        id: 3,
        text: "Bi-weekly"
    },
    {
        id: 4,
        text: "3 times a week"
    },
    {
        id: 5,
        text: "5 times a week"
    }
]

export const ManageArea = () => {
    const tomorrow = new Date(new Date().setDate(new Date().getDate()+1)).toLocaleDateString('en-CA')
    const params = useParams()
    const mountedRef = useRef(false)
    const { text } = useAppSelector(({ language }) => language)
    const currentBooking = useAppSelector(({ bookings }) => bookings.currentBooking)
    const [state, setState] = useState<Booking["main"]>({
        activeFrequency: 'Weekly',
        activeRooms: [""],
        date: new Date().toLocaleDateString('en-CA'),
        cleaning_date: tomorrow,
        cleaning_time: new Date().toLocaleTimeString('ru-RU').split(':').slice(0,-1).join(':'),
        one_time: currentBooking.main.one_time,
    })
    const longPress = useLongPress
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const toggleActiveRooms = (text: string) => {
        if (state.activeRooms.includes(text)) {
            setState(prev => ({ ...prev, activeRooms: prev.activeRooms.filter(room => room !== text) }))
        } else {
            setState(prev => ({ ...prev, activeRooms: [...prev.activeRooms, text] }))
        }
    }
    useEffect(() => {
        if(JSON.parse(localStorage.getItem("booking_state")!)) {
            dispatch(getLocalState())
            setState({...currentBooking.main, date: new Date().toLocaleDateString('en-CA')  })                       
        }
    }, [mountedRef.current])
    useEffect(() => {
        if(mountedRef.current) {
            dispatch(updateCurrentBooking({...currentBooking, main: {...state}}))
        } else {
            mountedRef.current = true
        }
    }, [state])

    const timePicker =         
            <TimePickerWrapper>
                <Title style={{ marginBottom: 10 }}>{text.cleaning_time}</Title>
                <CleaningFormContentTimeButton style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    <label>{state.cleaning_time ? state.cleaning_time : text.button_change}</label>
                    <input id="tester" type="time" onChange={(e) => setState(prev => ({ ...prev, cleaning_time: e.target.value }))} />
                </CleaningFormContentTimeButton>
            </TimePickerWrapper>        
    ;

    return (
        <ManageAreaWrapper>
            <TopContent title={text['Configurations']} text={text.manage_area_title} />
            <ManageAreaContent>                
                    {!currentBooking.main.one_time ?
                        <ManageAreaContentFrequency> 
                            <FrequencyButtonsWrapper>

                                {FREQUENCY_DATA.map((frequency) => (
                                    <ManageAreaContentFrequencyButtons
                                        key={frequency.id + frequency.text}
                                        className={state.activeFrequency === frequency.text ? 'active' : ''}
                                        onClick={() => setState(prev => ({ ...prev, activeFrequency: frequency.text }))}
                                    >
                                
                                        {
                                            //@ts-ignore
                                        text.frequency[frequency.text]}
                                
                                    </ManageAreaContentFrequencyButtons>
                                ))}

                            </FrequencyButtonsWrapper>
                            {timePicker}
                        </ManageAreaContentFrequency>
                        : 
                        
                        <DateTimeWrapper>
                            
                                <DateWrapper>
                                    <Title style={{ marginBottom: 10 }}>{text.cleaning_date}</Title>
                                    <Input style={{ marginBottom: 0 }} type="date" min={tomorrow} value={state.cleaning_date} onChange={(e) => setState(prev => ({ ...prev, cleaning_date: e.target.value }))} />
                                </DateWrapper>
                            
                            {timePicker}
                        </DateTimeWrapper>                   
                    }              
                
                {/* {timePicker} */}
                
                {/* <ManageAreaContentFrequency>
                    {!currentBooking.main.one_time ? FREQUENCY_DATA.map((frequency) => (
                        <ManageAreaContentFrequencyButtons
                            key={frequency.id + frequency.text}
                            className={state.activeFrequency === frequency.text ? 'active' : ''}
                            onClick={() => setState(prev => ({ ...prev, activeFrequency: frequency.text }))}
                        >
                            
                            {
                                //@ts-ignore
                            text.frequency[frequency.text]}
                        </ManageAreaContentFrequencyButtons>
                    )) : 
                        <div>
                            <Title style={{ marginBottom: 10 }}>{text.cleaning_date}</Title>
                            <Input type="date" min={tomorrow} value={state.cleaning_date} onChange={(e) => setState(prev => ({ ...prev, cleaning_date: e.target.value }))} />
                        </div>
                    }

                    <div style={{ alignSelf: 'center', marginBottom: 15, display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: 'stretch' }}>
                        <Title style={{ marginBottom: 10 }}>{text.cleaning_time}</Title>
                        <CleaningFormContentTimeButton>
                            <label>{state.cleaning_time ? state.cleaning_time : text.button_change}</label>
                            <input id="tester" type="time" onChange={(e) => setState(prev => ({ ...prev, cleaning_time: e.target.value }))} />
                        </CleaningFormContentTimeButton>
                    </div>
                </ManageAreaContentFrequency> */}
                <ManageAreaContentInfo>{text.tap_info}</ManageAreaContentInfo>
                <ManageAreaContentItemsBox>
                    <ManageAreaContentItems>
                        {MANAGE_AREA_DATA.map((item) => (
                            <ManageAreaContentItem
                                key={item.id + item.text}
                                onClick={() => toggleActiveRooms(item.text)}
                                {...longPress(() => {
                                    if(!state.activeRooms.includes(item.text)) {
                                        toggleActiveRooms(item.text)
                                    }
                                    if (params.id) {
                                        navigate(`/cleaning/${params.id || 'room'}/${item.text.split(' ').join('_')}`)
                                    } else {
                                        navigate(`/cleaning/${params.id || 'room'}/${item.text.split(' ').join('_')}`)
                                    }

                                }, 700)}
                            >
                                <ManageAreaContentItemImage
                                    className={state.activeRooms.includes(item.text) ? 'active' : ''}
                                >
                                    <img src={item.img} alt="" />
                                </ManageAreaContentItemImage>
                                
                                <ManageAreaContentItemText>{
                                    //@ts-ignore
                                text.rooms_name[item.text]}</ManageAreaContentItemText>
                            </ManageAreaContentItem>
                        ))}
                    </ManageAreaContentItems>
                </ManageAreaContentItemsBox>
                <ManageAreaButtons>
                    <Button outline onClick={() => {
                        dispatch(clearCurrentBooking())
                        navigate('/booking')
                    }}>{text.button_cancel}</Button>
                    <Button disabled={currentBooking.main.activeRooms.length < 2} onClick={() => navigate(`/preview/${params.id || ''}`)}>{text.preview}</Button>
                </ManageAreaButtons>
            </ManageAreaContent>
        </ManageAreaWrapper>
    )
}
