import { configureStore } from "@reduxjs/toolkit";
import user from "./userReducer";
import fillBaseInfo from "./fillBaseUserReducer";
import bookings from "./bookingsReducer";
import language from "./languageReducer";

export const store = configureStore({
  reducer: { user, fillBaseInfo, bookings, language },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
