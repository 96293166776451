import { createGlobalStyle } from "styled-components";
import AvenirNextDemiBoldTtf from './AvenirNext/AvenirNext-DemiBold.ttf'
import MontserratBold from './Montserrat/Montserrat-Bold.ttf'
import MontserratRegular from './Montserrat/Montserrat-Regular.ttf'


export default createGlobalStyle`
     @font-face {
        font-family: 'Avenir';
        src: local('Avenir'), local('Avenir'),
        url(${AvenirNextDemiBoldTtf}) format('opentype');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat'), local('Montserrat'),
        url(${MontserratBold}) format('opentype');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat'), local('Montserrat'),
        url(${MontserratRegular}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }

`