import styled from "styled-components";
import globeSvg from '../../assets/svg/globe.svg'

export const HeaderWrapper = styled.div`
    font-size: 24px;
    line-height: 36px;
    background-color: white;
    box-shadow: 0 0px 5px rgba(0,0,0,.6);
    z-index: 1000;
    position: relative;
    a {
        svg{
            fill: #BDBDBD;
            stroke: #BDBDBD;
        }
        /* &.active {
            svg {
                fill: #0072CE;
                stroke: #0072CE;
            }
        } */
    }
`
export const HeaderInner = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 26px;
`
export const HeaderBox = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    
`
export const HeaderBurger = styled.div`
    width: 18px;
    height: 3px;
    background-color: #0072CE;
    border-radius: 4px;
    margin-right: 11px;
    position: relative;
    visibility: hidden;
    &::before, &::after{
        content: '';
        position: absolute;
        display: block;
        width: 18px;
        height: 3px;
        background-color: #0072CE;
        border-radius: 4px
    }
    &::before{
        top: -5px;
    }
    &::after{
        bottom: -5px;
    }
`
export const HeaderLogo = styled.div`
    color: #0072CE;
    font-family: Avenir;
    letter-spacing: -.5px;
`
export const HeaderModal = styled.div`
    width: 100%;
    position: absolute;
    top: 45px;

`
export const HeaderModalList = styled.ul`

    list-style: none;
    background-color: white;
    color: #0072CE;
    font-size: .7em;
    text-align: center;
    font-weight: bold;
    padding: 10px 0px;

`
export const HeaderModalLink = styled.li`
    cursor: pointer;
`

export const HeaderIcon = styled.div`
display: flex;
        svg{
            fill: #BDBDBD;
            stroke: #BDBDBD;
        }
        &.active {
            svg {
                fill: #0072CE;
                stroke: #0072CE;
            }
        }

`
export const HeaderLanguageBox = styled.div`
    width: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #828282;
    padding: 5px 6px;
    border: 2px solid #828282;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-right: 18px;
    text-transform: uppercase;
    &::before {
        content: '';
        width: 15px;
        height: 15px;
        background-image: url(${globeSvg});
        background-repeat: no-repeat;
        background-position: center;
    }
`

