import React from 'react'
import styled from 'styled-components'
import { Welcome } from '../components'

export const WelcomePage = () => {
    return (
        <WelcomePageWrapper>
            <Welcome />
        </WelcomePageWrapper>
    )
}


const WelcomePageWrapper = styled.div`
    position: absolute;
    bottom: 0;
    top: 56px;
    left: 0;
    right: 0;
`