import React from 'react'
import { CleaningForm } from '../components'

export const CleaningFormPage = () => {
    return (
        <>
            <CleaningForm />
        </>
    )
}
