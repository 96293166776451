import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import $api from "../http/api";


type UserType = {
    id: string,
    email: string,
    token: string,
}

const initialState = {
    id: '',
    email: '',
    token: '',
    error: '',
    isLoading: true
}

type UserLoginType = {
    password: string,
    email: string
}

type UserRegistrationType = {
    email: string,
    name: string,
    city: string,
    lang: string
}

export const userLoginAction = createAsyncThunk('users/userLogin', async ({email, password}: UserLoginType, {rejectWithValue}) => {
    try {
        const {data} = await $api.post<UserType>('/users/login', {
            email,
            password
        })
        localStorage.setItem('token', `Bearer ${data.token}`)
        return data
    } catch (error: any) {
        return rejectWithValue(error.response.data.message || error)
    }
})

export const userRegistrationAction = createAsyncThunk('users/userRegistration', async ({email, name, city, lang}: UserRegistrationType, {rejectWithValue}) => {
    try {
        const {data} = await $api.post<UserType>('/users/register', {
            email,
            name,
            city,
            lang
        })
        localStorage.setItem('token', `Bearer ${data.token}`)
        return data
    } catch (error: any) {
        return rejectWithValue(error.response.data.message)
    }
})

export const userLoginTokenAction = createAsyncThunk('users/userLoginToken', async (_, {rejectWithValue}) => {
    try {
        const response = await $api.get<UserType>('/users/auth')
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data.message)
    }
})



const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        userLogout(state){
            state.id = ''
            state.email = ''
            state.error = ''
            state.token = ''
            state.isLoading = false
            localStorage.removeItem('token')
        },

        clearError(state) {
            state.error = ''
        }
    },
    extraReducers: {
        [userLoginAction.pending.type]: (state) => {
            state.isLoading = true
        },
        [userLoginAction.fulfilled.type]: (state, {payload}: PayloadAction<UserType> ) => {
            state.isLoading = false
            state.error = ''
            state.email = payload.email
            state.token = payload.token
            state.id = payload.id
        },
        [userLoginAction.rejected.type]: (state, {payload}: PayloadAction<string>) => {
            state.isLoading = false
            state.email = ''
            state.token = ''
            state.error = payload
        },
        [userRegistrationAction.pending.type]: (state) => {
            state.isLoading = true
        },
        [userRegistrationAction.fulfilled.type]: (state, {payload}: PayloadAction<UserType> ) => {
            state.isLoading = false
            state.error = ''
            state.email = payload.email
            state.token = payload.token
            state.id = payload.id
        },
        [userRegistrationAction.rejected.type]: (state, {payload}: PayloadAction<string>) => {
            state.isLoading = false
            state.email = ''
            state.token = ''
            state.error = payload
        },
        [userLoginTokenAction.pending.type]: (state) => {
            state.isLoading = true
        },
        [userLoginTokenAction.fulfilled.type]: (state, {payload}: PayloadAction<UserType> ) => {
            state.isLoading = false
            state.error = ''
            state.email = payload.email
            state.token = payload.token
            state.id = payload.id
        },
        [userLoginTokenAction.rejected.type]: (state, {payload}: PayloadAction<string>) => {
            state.isLoading = false
            state.email = ''
            state.token = ''
        },
    }
})



export const {userLogout, clearError} = userSlice.actions
export default userSlice.reducer