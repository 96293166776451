import React from 'react'
import { useNavigate } from 'react-router'
import { ProgressBar, Registration } from '../components'
import { useAppSelector } from '../hooks/redux'

export const RegistrationPage = () => {
    const {email, isLoading} = useAppSelector(({user}) => user)
    const navigate = useNavigate()

    if(isLoading) {
        return <ProgressBar />
    }
    if(email) {
        navigate('/account')
    }
    return (
        <>
            <Registration />
        </>
    )
}
