//@ts-nocheck
import { ReactEventHandler, useRef } from "react";

const useLongPress = (callback: () => any, delay: number) => {
  const timer = useRef<null>(null);

  const onTouchStart: ReactEventHandler<TouchEvent | HTMLElement> = (e) => {
    longPress();
  };
  const onTouchEnd: ReactEventHandler<TouchEvent | HTMLElement> = (e) =>
    clearTimeout(timer.current);
  const onTouchMove: ReactEventHandler<TouchEvent | HTMLElement> = (e) =>
    clearTimeout(timer.current);
  const onMouseDown: ReactEventHandler<MouseEvent | HTMLElement> = (e) => {
    longPress();
  };
  const onMouseUp: ReactEventHandler<MouseEvent | HTMLElement> = (e) =>
    clearTimeout(timer.current);
  const onMouseLeave: ReactEventHandler<MouseEvent | HTMLElement> = (e) =>
    clearTimeout(timer.current);

  const longPress = (...args) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      if (window.navigator && window.navigator.vibrate) {
        window.navigator.vibrate(20);
      }
      return callback(...args);
    }, delay);
  };

  return { onTouchStart, onTouchEnd, onMouseUp, onMouseDown, onMouseLeave, onTouchMove };
};

export default useLongPress;
