import React from 'react'
import { useNavigate } from 'react-router'
import { Login, ProgressBar } from '../components'
import { useAppSelector } from '../hooks/redux'

export const LoginPage = () => {
    const {email, isLoading} = useAppSelector(({user}) => user)
    const navigate = useNavigate()

    if(isLoading) {
        return <ProgressBar />
    }
    if(email) {
        navigate('/account')  
    }
    return (
        <>
            <Login />
        </>
    )
}
