//@ts-nocheck
import React, { useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { ProgressBar, TopContent } from '..'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { clearCurrentBooking, createBooking, updateBookingAsync } from '../../redux/bookingsReducer'
import { Button } from '../../style'
import { PreviewButtons, PreviewHeader, PreviewInner, PreviewMainInformation, PreviewRoomInformation, PreviewTitle, PreviewWrapper } from './previewStyled'

export const Preview = () => {
    const rooms = useAppSelector(({ bookings }) => bookings.currentBooking.rooms)
    const {text} = useAppSelector(({ language }) => language )
    const { currentBooking } = useAppSelector(({ bookings }) => bookings)
    const { fillBase, isLoading } = useAppSelector(({ fillBaseInfo }) => fillBaseInfo)
    const { id } = useAppSelector(({ user }) => user)
    
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    
    const saveManageArea = () => {
        if (params.id) {
            dispatch(updateBookingAsync({ id: params.id!, values: { main: currentBooking.main, rooms: currentBooking.rooms } }))
                .then(() => {
                    dispatch(clearCurrentBooking())
                    localStorage.removeItem('booking_state')
                    navigate('/booking')
                })
        } else {
            dispatch(createBooking({ id, values: { main: currentBooking.main, rooms: currentBooking.rooms } }))
                .then(() => {
                    dispatch(clearCurrentBooking())
                    localStorage.removeItem('booking_state')
                    navigate('/booking')
                })
        }
    }    

    useEffect(() => {
        if(!currentBooking.main.date) {
            navigate('/booking')
        }
    }, []);

    if (isLoading) {
        return <ProgressBar />
    }
    return (
        <PreviewWrapper>
            <TopContent title={text["Request for quotation (RFQ)"]} />
            <PreviewInner>
                <PreviewMainInformation>
                    {!!Object.keys(fillBase).length && 
                    //@ts-ignore
                    fillBase["Contact person"].toString().toLocaleUpperCase() || text.you} {text.ordered} {currentBooking.main.one_time ? text.button_one_time_cleaning : text.button_contractual_cleaning}  {!currentBooking.main.one_time ? text.frequency[currentBooking.main.activeFrequency] : `on ${new Date(currentBooking.main.cleaning_date).toLocaleDateString("ru")}`} {text.at} {currentBooking.main.cleaning_time}
                </PreviewMainInformation>
                {currentBooking.main.activeRooms.map((room) => {
                    const replaceRoom = room.split(' ').join('_')
                    const roomValues = Object.values(rooms[replaceRoom] || '')
                    
                    if (!room) {
                        return null
                    }
                    return <PreviewRoomInformation key={room}>{
                    //@ts-ignore
                    text.rooms_name[room]}:

                <span> {roomValues[3] ? ` ${roomValues[3]} m²`  : `${text['size not specified']}`},</span>
                    {roomValues[1] ? 
                        roomValues[1] === 'None' ?
                            // "Floor not to be cleaned" instead of "Not to be cleaned floor"
                            <span>{text["floor"][0].toUpperCase()}{text["floor"].slice(1)} {text.room_config.floor[roomValues[1]].toLowerCase()},</span> 
                            : <span>{text.room_config.floor[roomValues[1]]} {text["floor"]}, </span>
                    : null}                    
                    <span>{roomValues[2] ? `${roomValues[2]} ${text['windows']} ` : `${text['no windows']}` },</span>
                    <span>{roomValues[5] ? `${text['photo attached']}` : `${text['photo not specified']}` },</span>
                    {currentBooking.main.one_time ? 
                    <span>{roomValues[4] && roomValues[4] ? `${text['cleaning requested']} ${new Date(currentBooking.main.cleaning_date).toLocaleDateString("ru")} at ${roomValues[4]} ` : `${text['cleaning requested']} ${new Date(currentBooking.main.cleaning_date).toLocaleDateString("ru")} ${text.at} ${currentBooking.main.cleaning_time}` }</span> 
                    :
                    //@ts-ignore
                    <span>{roomValues[0] && roomValues[0] ? `${roomValues[0]} ${text.at} ${roomValues[4] || currentBooking.main.cleaning_time} ` : `${text.frequency[currentBooking.main.activeFrequency] || 'frequency of cleaning not specified'} ${text.at} ${currentBooking.main.cleaning_time}` }</span>
                    }
                        </PreviewRoomInformation>
                })}
                <PreviewButtons>
                    <Button outline onClick={() => navigate(`/manage/${params.id || ''}`)}>{text.button_cancel}</Button>
                    {id ?
                        <Button onClick={saveManageArea} >{text.button_confirm}</Button> 
                        :
                        <Link to='/account'>
                            <Button>{text.button_confirm}</Button>
                        </Link>
                    }
                </PreviewButtons>
            </PreviewInner>
        </PreviewWrapper>
    )
}