import React, { useState } from 'react'
import { LoginError, LoginForm, LoginFormError, LoginFormInput, LoginFormInputBox, LoginRegistrationLink } from './loginStyled'
import { useFormik } from 'formik'
import { Button, Title, Wrapper } from '../../../style'
import { Link } from 'react-router-dom'
import visibleEye from '../../../assets/img/visibility.png'
import hiddenEye from '../../../assets/img/hidden.png'
import { validationLogin } from './yupValidation'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { userLoginAction } from '../../../redux/userReducer'

export const Login = () => {
    const [showPassword, setShowPassword] = useState(true)
    const { error } = useAppSelector(({ user }) => user)
    const { text } = useAppSelector(({ language }) => language)
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: validationLogin(text),
        onSubmit: (values) => dispatch(userLoginAction({ email: values.email.toLocaleLowerCase(), password: values.password }))
    })
    return (
        <Wrapper>
            <Title>{text.login}</Title>
            <LoginForm onSubmit={(e) => {e.preventDefault(); formik.handleSubmit(e)}}>
                <LoginFormInputBox>
                    <LoginFormInput error={!!(formik.touched.email && formik.errors.email)} id="email" type="text" placeholder={text["Email"]} {...formik.getFieldProps('email')} />
                    {formik.touched.email && formik.errors.email ? (
                        <LoginFormError>{formik.errors.email}</LoginFormError>
                    ) : null}
                </LoginFormInputBox>
                <LoginFormInputBox>
                    <LoginFormInput error={!!(formik.touched.password && formik.errors.password)} id="password" type={showPassword ? 'password' : 'text'} placeholder={text["Password"]}{...formik.getFieldProps('password')} />
                    <img style={{ cursor: 'pointer' }} onClick={() => setShowPassword(prev => !prev)} src={showPassword ? hiddenEye : visibleEye} alt="" />
                    {formik.touched.password && formik.errors.password ? (
                        <LoginFormError>{formik.errors.password}</LoginFormError>
                    ) : null}
                </LoginFormInputBox>
                {error && <LoginError>{error}</LoginError>}
                <LoginRegistrationLink>{text.new_to_siivous} <Link to="/registration">{text.button_sign_up}</Link></LoginRegistrationLink>
                <Button disabled={!(formik.isValid && formik.dirty)} type="submit">{text.button_next}</Button>
            </LoginForm>
        </Wrapper>
    )
}
