import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { CleaningFormContent, CleaningFormContentComment, CleaningFormContentButton, CleaningFormContentFloorButtons, CleaningFormContentFrequency, CleaningFormContentFrequencyButtons, CleaningFormContentMetersBox, CleaningFormContentMetersInput, CleaningFormContentTime, CleaningFormContentTimeButton, CleaningFormContentTitle, CleaningFormContentWrapper, CleaningFormHeader, CleaningFormHeaderImage, CleaningFormHeaderText, CleaningFormHeaderTitle, CleaningFormWrapper, CleaningFormContentWindows, CleaningFormContentFile, CleaningFormContentButtons } from './cleaningFormStyled'
import backgroundHeader from '../../assets/svg/welcome.svg'
import { FREQUENCY_DATA } from '../ManageArea'
import { Button } from '../../style'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { updateCurrentBooking } from '../../redux/bookingsReducer'
import { Booking } from '../../interfaces/bookings'
import { ProgressBar, TopContent } from '..'




const FLOOR_BUTTONS = [
        "None",
        "Plastic",
        "Stone",
        "Linoleum",
        "Other"
    ]

export const CleaningForm = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({
        activeFrequency: '',
        activeFloor: '',
        activeWindow: '',
        meterValue: 30,
        timeValue: '',
        fileValue: '',
        fileName: '',
        commentValue: ''
    })
    const params = useParams()
    const timeRef = useRef<HTMLInputElement | null>(null)
    const fileRef = useRef<HTMLInputElement | null>(null)   
    const {currentBooking} = useAppSelector(({bookings}) => bookings)
    const {text} = useAppSelector(({language}) => language)
    const data = useAppSelector(({ bookings }) => bookings.data.find(booking => booking._id === params.id))
    const {isLoading} = useAppSelector(({ bookings }) => bookings)
    const dispatch = useAppDispatch()

    const  encodeFileAsURL = (elem: FileList | null) => {
        if(elem === null) {
            return false
        }
        const file = elem[0];
        setState(prev => ({...prev, fileName: file.name}))

        const reader = new FileReader();
        reader.onloadend = () => {
            setState(prev => ({...prev, fileValue: `${reader.result}`}))
        }
        reader.readAsDataURL(file)
    }
    const saveFormHandler = () => {
        const obj: Booking = {
            ...currentBooking,
            rooms: {
                ...currentBooking.rooms,
                [`${params.room}`]: {
                   ...state
                }
            }
        }
        dispatch(updateCurrentBooking(obj))
        navigate(`/manage/${currentBooking._id || ''}`)
    }

    useEffect(() => {
        //@ts-ignore
         params.room && currentBooking.rooms[params.room] && setState(currentBooking.rooms[params.room])
    }, [currentBooking, params.room])

    useEffect(() => {
        if(!currentBooking.main.date) {
            navigate('/manage')
        }
    }, []);
    
    if(isLoading) {
        return <ProgressBar />
    }
    return (
        <CleaningFormWrapper>
            <TopContent title={
                //@ts-ignore
                text.rooms_name[params.room?.split('_').join(' ')]} text={text.room_config.title} />
            <CleaningFormContent>
                <CleaningFormContentFrequency>
                    {!currentBooking.main.one_time ? FREQUENCY_DATA.map((frequency) => (
                        <CleaningFormContentFrequencyButtons
                            key={frequency.id + frequency.text}
                            className={state.activeFrequency === frequency.text ? 'active' : ''}
                            onClick={() => setState(prev => ({...prev, activeFrequency: frequency.text}))}
                        >
                            {
                                //@ts-ignore
                            text.frequency[frequency.text]}
                        </CleaningFormContentFrequencyButtons>
                    )) : ''}
                </CleaningFormContentFrequency>
                <CleaningFormContentWrapper>
                    <CleaningFormContentTitle>{text.room_config.meters}</CleaningFormContentTitle>
                    <CleaningFormContentMetersBox>
                        <label >{state.meterValue} m<sup>2</sup></label>
                        <CleaningFormContentMetersInput
                            type="range"
                            min={0}
                            max={100}
                            value={state.meterValue}
                            onChange={(e) => setState(prev => ({...prev, meterValue: +e.target.value}))}
                        />
                    </CleaningFormContentMetersBox>
                </CleaningFormContentWrapper>
                <CleaningFormContentWrapper>
                    <CleaningFormContentTime>
                        <CleaningFormContentTitle>{text.cleaning_time}</CleaningFormContentTitle>
                        <CleaningFormContentTimeButton onClick={() => timeRef.current?.click()}>
                            <label>{state.timeValue ? state.timeValue : text.button_change}</label>
                            <input id="tester" ref={timeRef} type="time" onChange={(e) => setState(prev => ({...prev, timeValue: e.target.value}))} />
                        </CleaningFormContentTimeButton>
                    </CleaningFormContentTime>
                </CleaningFormContentWrapper>
                <CleaningFormContentWrapper>
                    <CleaningFormContentTitle>{text.room_config.floor_title}</CleaningFormContentTitle>
                    <CleaningFormContentFloorButtons>
                        {FLOOR_BUTTONS.map((button, index) => <CleaningFormContentButton
                            key={index + button}
                            className={state.activeFloor === button ? 'active' : ''}
                            onClick={() => setState(prev => ({...prev, activeFloor: button}))}
                        >
                            {
                                //@ts-ignore
                            text.room_config.floor[button]
                            }
                        </CleaningFormContentButton>)}
                    </CleaningFormContentFloorButtons>
                </CleaningFormContentWrapper>
                <CleaningFormContentWrapper>
                    <CleaningFormContentTitle>{text.room_config.windows_title}</CleaningFormContentTitle>
                    <CleaningFormContentWindows>
                        {text.room_config.windows.map((button, index) => <CleaningFormContentButton
                            key={index + button}
                            className={state.activeWindow === button ? 'active' : ''}
                            onClick={() => setState(prev => ({...prev, activeWindow: button}))}
                        >
                            {button}
                        </CleaningFormContentButton>)}
                    </CleaningFormContentWindows>
                </CleaningFormContentWrapper>
                <CleaningFormContentWrapper>
                <CleaningFormContentTitle>{text.room_config.attachments.title}</CleaningFormContentTitle>
                <CleaningFormContentFile>
                    <label>{state.fileName ? state.fileName : text.room_config.attachments.no_file}</label>
                    <button onClick={() => fileRef.current?.click()}>{text.room_config.attachments.btn}</button>
                    <input ref={fileRef} type="file" accept="image/png, image/jpeg" onChange={(e) => encodeFileAsURL(e.target.files)} />
                </CleaningFormContentFile>
                </CleaningFormContentWrapper>
                <CleaningFormContentWrapper>
                <CleaningFormContentTitle>{text.room_config.comment}</CleaningFormContentTitle>
                <CleaningFormContentComment value={state.commentValue} onChange={(e) => setState(prev => ({...prev, commentValue: e.target.value}))}/>
                </CleaningFormContentWrapper>
            <CleaningFormContentButtons>
                <Button outline onClick={() => window.history.go(-1)}>{text.button_cancel}</Button>
                <Button onClick={saveFormHandler}>{text.button_save}</Button>
            </CleaningFormContentButtons>
            </CleaningFormContent>
        </CleaningFormWrapper>
    )
}
