import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ManageArea, ProgressBar } from '../components'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { updateCurrentBooking } from '../redux/bookingsReducer'

export const ManageAreaPage = () => {
    const {id} = useParams()
    const dispatch = useAppDispatch()
    const data = useAppSelector(({ bookings }) => bookings.data.find(booking => booking._id === id))
    const currentBooking = useAppSelector(({bookings}) =>  bookings.currentBooking)
    const {isLoading} = useAppSelector(({ bookings }) => bookings)
    useEffect(() => {
        if(id) {
            if(!currentBooking._id) {
                data?._id && dispatch(updateCurrentBooking(data!)) 
            }
        }
    }, [id, data])
    
    if(isLoading) {
        return <ProgressBar />
    }
    return (
        <>
            <ManageArea />
        </>
    )
}
