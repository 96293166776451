

import React from 'react'
import { Bookings } from '../components'

export const BookingsPage = () => {

    
    return (
        <>
            <Bookings />
        </>
    )
}
