import React, { useEffect, useState } from 'react'
import { HeaderBox, HeaderBurger, HeaderIcon, HeaderInner, HeaderLanguageBox,  HeaderLogo, HeaderModal, HeaderModalLink, HeaderModalList, HeaderWrapper } from './headerStyled'
import {ReactComponent as CartSvg} from '../../assets/svg/cart.svg'
import {ReactComponent as LoginSvg} from '../../assets/svg/login.svg'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { changeLanguageAction } from '../../redux/languageReducer'
import { userLogout } from '../../redux/userReducer'


export const Header = () => {
    const { email } = useAppSelector(({ user }) => user)
    const {lang, text} = useAppSelector(({language}) => language)
    const [openModal, setOpenModal] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        window.addEventListener('click', () => setOpenModal(false))
        return () => {
        window.removeEventListener('click', () => setOpenModal(false))
        }
    }, [])
    return (
        <HeaderWrapper>
            <HeaderInner>
                <HeaderBox>
                    <HeaderBurger />
                    <Link to="/">
                        <HeaderLogo>Siivous.com</HeaderLogo>
                    </Link>
                </HeaderBox>
                <HeaderBox>
                    <HeaderLanguageBox onClick={() => dispatch(changeLanguageAction(lang === 'en' ? 'fi' : 'en'))}>
                        {lang}
                    </HeaderLanguageBox>
                    {email && <NavLink to="/booking">
                        <HeaderIcon style={{ marginRight: 14 }}>
                            <CartSvg/>
                        </HeaderIcon>
                    </NavLink>}
                        <HeaderIcon onClick={(e) => {
                            e.stopPropagation()
                            setOpenModal(true)
                            
                            }}>
                            <LoginSvg />
                        </HeaderIcon>
                            {openModal && <HeaderModal onClick={(e) => e.stopPropagation()}>
                                <HeaderModalList>
                                    <NavLink to={email ? '/account' : '/login'}>
                                        <HeaderModalLink onClick={() => setOpenModal(false)}>{email ? text["details"] : text["login"].replace(":", "")}</HeaderModalLink>
                                    </NavLink>
                                        {email && <HeaderModalLink onClick={() => {
                                            dispatch(userLogout())
                                            setOpenModal(false)
                                            navigate('/')
                                            }}>
                                            {text['Logout']}
                                            </HeaderModalLink>}
                                </HeaderModalList>
                            </HeaderModal>}
                </HeaderBox>
            </HeaderInner>
        </HeaderWrapper>
    )
}
