import React from 'react'
import { WelcomeBackgroundAnimate, WelcomeBox, WelcomeImage, WelcomeSpan, WelcomeTitle, WelcomeWrapper } from './welcomeStyled'
import welcomeSvg from '../../assets/svg/welcome.svg'
import { Button } from '../../style'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../hooks/redux'

export const Welcome = () => {
    const { email } = useAppSelector(({ user }) => user)
    const { text } = useAppSelector(({ language }) => language);

    return (
        <WelcomeWrapper>
            <WelcomeBackgroundAnimate />
            <WelcomeBox style={{ backgroundColor: '#1A7EDA' }}>
                <WelcomeImage>
                    <img src={welcomeSvg} alt="welcome" />
                </WelcomeImage>
                <WelcomeTitle>{text.welcome}</WelcomeTitle>

                <WelcomeSpan>{text.introduction}</WelcomeSpan>
            </WelcomeBox>
            {email ? <WelcomeBox style={{ padding: 20, zIndex: 0 }}>
                <Link to="/booking">
                    <Button style={{ marginBottom: 14 }}>{text.your_bookings}</Button>
                </Link>
            </WelcomeBox> :
                <WelcomeBox style={{ padding: 20, zIndex: 0 }}>
                    <Link style={{ marginBottom: 14 }} to="/booking">
                        <Button style={{ marginBottom: 0, marginLeft: 0 }}>{text.start_siivous}</Button>
                    </Link>
                    <Link style={{ marginBottom: 14 }} to="/login">
                        <Button style={{ marginBottom: 0, marginLeft: 0 }}>{text.button_sign_in}</Button>
                    </Link>
                </WelcomeBox>}
        </WelcomeWrapper>
    )
}
