import React from 'react'
import { LoginForm, LoginFormError, LoginFormInput, LoginFormInputBox} from './registrationStyled'
import { useFormik } from 'formik'
import { Button, Title, Wrapper } from '../../../style'
import { validationRegistration } from './yupValidation'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { userRegistrationAction } from '../../../redux/userReducer'
import { LoginError } from '../Login/loginStyled'

export const Registration = () => {
    const {error} = useAppSelector(({user}) => user)
    const {text, lang} = useAppSelector(({language}) => language)
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            city: ''
        },
        validationSchema: validationRegistration(text),
        onSubmit: (values) => {
            dispatch(userRegistrationAction({email: values.email.toLocaleLowerCase(), name: values.name, city: values.city, lang}))
        }
    })

    return (
        <Wrapper>
            <Title>{text.registration}</Title>
            <LoginForm onSubmit={(e) => {e.preventDefault(); formik.handleSubmit(e)}}>
                <LoginFormInputBox>
                    <LoginFormInput error={!!(formik.touched.email && formik.errors.email)} id="email" type="text" placeholder={text["Email"]} {...formik.getFieldProps('email')} />
                    {formik.touched.email && formik.errors.email ? (
                        <LoginFormError>{formik.errors.email}</LoginFormError>
                    ) : null}
                </LoginFormInputBox>
                <LoginFormInputBox>
                    <LoginFormInput error={!!(formik.touched.name && formik.errors.name)} type="text" placeholder={text["Name"]} {...formik.getFieldProps('name')} />
                    {formik.touched.name && formik.errors.name ? (
                        <LoginFormError>{formik.errors.name}</LoginFormError>
                    ) : null}
                </LoginFormInputBox>
                <LoginFormInputBox>
                <LoginFormInput error={!!(formik.touched.city && formik.errors.city)} type="text" placeholder={text["City"]} {...formik.getFieldProps('city')} />
                    {formik.touched.city && formik.errors.city ? (
                        <LoginFormError>{formik.errors.city}</LoginFormError>
                    ) : null}
                </LoginFormInputBox>
                {error && <LoginError>{error}</LoginError>}
                <Button disabled={!(formik.isValid && formik.dirty)} type="submit">{text.button_sign_up}</Button>
            </LoginForm>
        </Wrapper>
    )
}
