import axios from "axios";


const API_URL = process.env.REACT_APP_API

const $api = axios.create({
    baseURL: API_URL,
    // withCredentials: true,
})


$api.interceptors.request.use(config => {
    config.headers!.Authorization = localStorage.getItem('token') || ''
    return config
})

// $api.interceptors.response.use((config) => {
//     return config;
// }, async (error) => {
//     const originalRequest = error.config
//     if(error.response.status === 401) {
//         try {
//             const response = await axios.get(`${API_URL}/user/refresh`, {withCredentials: true})
//             localStorage.setItem('token', response.data.accessToken)
//             return axios.request(originalRequest)
//         } catch (error) {
//             console.log(`Don't authorization, ${error}`);
//         }
//     }
//     return Promise.reject(error)
// })


export default $api