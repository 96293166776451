import styled from "styled-components";
import cleanSvg from "../../assets/svg/clean.svg";
import deleteSvg from "../../assets/svg/delete.svg";
import changeSvg from "../../assets/svg/change.svg";
import welcomeSvg from "../../assets/svg/welcome.svg";
import { Button } from "../../style";

export const BookingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const BookingsItems = styled.div`
  width: 100%;

  @media only screen and (min-width: 768px) {
        max-width: 60%;
        margin: 0 auto;
    }
`;
export const BookingsItem = styled.div`
  border-radius: 6.94845px;
  box-shadow: 0px 6.94845px 34.7423px rgba(9, 44, 76, 0.16);
  overflow: hidden;
  margin-bottom: 15px;
`;
export const BookingsItemHeader = styled.div`
  width: 100%;
  height: 110px;
  background-color: #1a7eda;
  background-image: url(${cleanSvg});
  background-repeat: no-repeat;
  background-position: -1px 21px;
  position: relative;
`;
export const BookingsItemHeaderStatus = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 10px;
  line-height: 19px;
  color: #ffffff;
`;
export const BookingsItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 17px 25px;
  background-color: white;
`;
export const BookingsItemContentInfo = styled.div``;
export const BookingsItemContentInfoText = styled.div`
  font-size: 14px;
  line-height: 25px;
  color: #000000;
  font-weight: bold;
`;
export const BookingsItemContentIcons = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
`;
export const BookingsItemContentIconDelete = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${deleteSvg});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
`;
export const BookingsItemContentIconChange = styled.div`
  width: 26px;
  height: 26px;
  background-image: url(${changeSvg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 4px;
`;

export const BookingImage = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${welcomeSvg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 40px;
`;
export const BookingText = styled.p`
  margin-top: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #929292;
  margin-bottom: 40px;
`;


export const BookingsModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: unset;
  background-color: rgba(0,0,0, .3);
  z-index: 1000;
`
export const BookingsModalInner = styled.div`
  width: 90%;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
`
export const BookingsModalText = styled.div`
  text-align: center;
  margin-bottom: 15px;
`
export const BookingsModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BookingsModalButton = styled(Button)`
  width: 40%;
`
