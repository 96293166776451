import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { Button, Input, Title, Wrapper } from '../../style'
import { BasicFormForm, BasicFormNote } from './basicFormStyled'
import { updateFillBase } from '../../redux/fillBaseUserReducer'
import { useNavigate } from 'react-router'
import { LoginError, LoginForm, LoginFormInput, LoginFormInputBox } from '../Auth/Login/loginStyled'
import { clearError, userLogout, userRegistrationAction } from '../../redux/userReducer'
import { clearCurrentBooking, createBooking } from '../../redux/bookingsReducer'
import { Link } from 'react-router-dom'

export const BasicForm = () => {
    const { id } = useAppSelector(({ user }) => user)
    const { error } = useAppSelector(({ user }) => user)
    const { text, lang } = useAppSelector(({ language }) => language)
    const { fillBase } = useAppSelector(({ fillBaseInfo }) => fillBaseInfo)
    const { currentBooking } = useAppSelector(({ bookings }) => bookings)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const formikFillBase = useFormik({
        enableReinitialize: true,
        initialValues: fillBase,
        onSubmit: (values) => {
            dispatch(updateFillBase({ id, fillBase: values }))
                .then(() => navigate('/booking'))
        }
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            city: ''
        },
        onSubmit: (values) => {
            dispatch(userRegistrationAction({ email: values.email.toLocaleLowerCase(), name: values.name, city: values.city, lang  }))
                .then(({payload}: any) => {                    
                    if (payload === 'This user is already registered!') {                        
                        return;
                    }

                    else if (payload) {
                        navigate('/booking')
                        
                        dispatch(createBooking({ id: payload.id, values: { main: currentBooking.main, rooms: currentBooking.rooms } }))
                        .then(() => {                            
                            dispatch(clearCurrentBooking())                            
                        })
                    }
                })
        }
    })

    if (!id) {
        return (
            <Wrapper>
                <Title>{text.fill_basic.title}</Title>
                <LoginForm onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e) }}>
                    <LoginFormInputBox>
                        <LoginFormInput error={!!(formik.touched.email && formik.errors.email)} id="email" type="text" placeholder={text.fill_basic_info.email} {...formik.getFieldProps('email')} />
                    </LoginFormInputBox>
                    <LoginFormInputBox>
                        <LoginFormInput error={!!(formik.touched.name && formik.errors.name)} type="text" placeholder={text.fill_basic_info.name} {...formik.getFieldProps('name')} />
                    </LoginFormInputBox>
                    <LoginFormInputBox>
                        <LoginFormInput error={!!(formik.touched.city && formik.errors.city)} type="text" placeholder={text.fill_basic_info.city} {...formik.getFieldProps('city')} />
                    </LoginFormInputBox>
                    {error ? null : <BasicFormNote>{text.fill_basic_info.note}</BasicFormNote>}
                    {error && <LoginError>{error} <Link onClick={() => dispatch(clearError())} to="/login">{text.button_sign_in}</Link></LoginError>}
                    <Button type="submit">{text.button_save}</Button>
                </LoginForm>
            </Wrapper>
        )
    }
    return (
        <Wrapper>
            <Title>{text.fill_basic.title}</Title>
            <BasicFormForm onSubmit={formikFillBase.handleSubmit}>
                <Input placeholder={text.fill_basic.company_name} type="text" {...formikFillBase.getFieldProps('Company name')} />
                <Input placeholder={text.fill_basic.company_reg} type="text" {...formikFillBase.getFieldProps('Company reg')} />
                <Input placeholder={text.fill_basic.city} type="text" {...formikFillBase.getFieldProps('Contact city')} />
                <Input placeholder={text.fill_basic.person} type="text" {...formikFillBase.getFieldProps('Contact person')} />
                <Input placeholder={text.fill_basic.email} type="text" {...formikFillBase.getFieldProps('Contact person email')} />
                <Input placeholder={text.fill_basic.phone} type="text" {...formikFillBase.getFieldProps('Contact person phone')} />
                <BasicFormNote>{text.fill_basic_info.note}</BasicFormNote>
                <Button type="submit">{text.button_save}</Button>
            </BasicFormForm>
        </Wrapper>
    )
}
