import styled from "styled-components";
import infoSvg from '../../assets/svg/info.svg'

export const ManageAreaWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 55px;
  left: 0;
  right: 0;
`;
export const ManageAreaHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #1a7eda;
  padding: 70px 0;
`;
export const ManageAreaHeaderImage = styled.img`
  width: 100%;
  height: 138px;
  margin-bottom: 44px;
`;
export const ManageAreaHeaderText = styled.div`
  color: white;
`;



export const ManageAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 28px 20px;

  @media only screen and (min-width: 768px) {
    max-width: 65%;
    margin: 0 auto;
  }
`;
export const ManageAreaContentInfo = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  font-size: 16px;
  line-height: 19px;
  color: #929292;
  text-align: left;
  margin: 0 auto;
  margin-bottom: 20px;
  &::before{
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    background-image: url(${infoSvg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 16px;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 15px;
  }
`;
export const ManageAreaContentFrequency = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;  
`;

export const FrequencyButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-bottom: 15px;
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    gap: 50px;
  }

  @media only screen and (min-width: 1200px) {    
    gap: 80px;
  }
`;

export const DateWrapper = styled.div`
  @media only screen and (max-width: 767px) {
    margin-bottom: 12px;
  }
`;

export const TimePickerWrapper = styled.div`
  align-self: stretch;  
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center;

  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

export const ManageAreaContentFrequencyButtons = styled.button`
  min-width: 150px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #4f4f4f;
  background-color: transparent;
  padding: 10px 2px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  flex: 1;
  margin: 2px;
  &.active {
    background: #f4a710;
    color: white;
    border: 1px solid transparent;
  }
`;

export const ManageAreaContentItemsBox = styled.div`
  margin-bottom: 30px;
`;
export const ManageAreaContentItems = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 10px;
  justify-content: center;
  padding: 10px;
  width: 90vw;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    max-width: 75%;    
  }
`;
export const ManageAreaContentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const ManageAreaContentItemImage = styled.div`
  width: 88px;
  height: 88px;
  background-color: #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 10px;
  pointer-events: none;
  user-select: none;
  img {
    height: 35px;
    pointer-events: none;
  }
  &.active {
    background-color: #5cc297;
  }
`;
export const ManageAreaContentItemText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  user-select: none;
`;


export const ManageAreaButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  button {
    max-width: 140px;
  }
`
