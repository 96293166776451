import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import $api from '../http/api'
import { Booking } from '../interfaces/bookings'


type BookingState = {
    data: Booking[]
    currentBooking: Booking
    isLoading: boolean,
    error: string,
}
const tomorrow = new Date(new Date().setDate(new Date().getDate()+1)).toLocaleDateString('en-CA')
const initialState: BookingState = {
    data: [],
    currentBooking: {
        _id: "",
        main: {
            activeFrequency: 'Weekly',
            activeRooms: [""],
            date: '',
            cleaning_date: tomorrow,
            cleaning_time: new Date().toLocaleTimeString('ru-RU').split(':').slice(0,-1).join(':'),
            one_time: false,
        },
        rooms: {},
    },
    isLoading: false,
    error: '',
}


export const fetchBookings = createAsyncThunk('bookings/fetchBookings', async (id: string, { rejectWithValue }) => {
    try {
        const { data } = await $api.get<any>(`/bookings/personal/${id}`)
        return data
    } catch (error: any) {
        return rejectWithValue(error.response.data.message)
    }
})

export const createBooking = createAsyncThunk('bookings/createBooking', async ({ id, values }: { id: string, values: {} }, { rejectWithValue }) => {
    try {
        const { data } = await $api.post<any>('/bookings/add', {
            id,
            values
        })
        return data
    } catch (error: any) {
        return rejectWithValue(error.response.data.message)
    }
})

export const deleteBooking = createAsyncThunk('bookings/deleteBooking', async (id: string, { rejectWithValue }) => {
    try {
        await $api.get(`/Bookings/delete/${id}`)
        return id
    } catch (error: any) {
        return rejectWithValue(error.response.data.message)
    }
})


export const updateBookingAsync = createAsyncThunk('bookings/updateBooking', async ({ id, values }: { id: string, values: any }, { rejectWithValue }) => {
    try {
        const { data } = await $api.put<Booking>('/bookings/update', {
            id,
            values
        })

        return data
    } catch (error: any) {
        return rejectWithValue(error.response.data.message)
    }
})

const bookingsSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        getLocalState: (state) => {
            state.currentBooking = JSON.parse(localStorage.getItem("booking_state")!)
        },
        updateCurrentBooking: (state, { payload }: PayloadAction<Booking>) => {            
            state.currentBooking = { ...state.currentBooking, ...payload }            
            localStorage.setItem("booking_state", JSON.stringify(state.currentBooking))
        },
        changeRooms: (state, { payload }: PayloadAction<string[]>) => {
            state.currentBooking.main.activeRooms = payload
        },
        clearCurrentBooking: (state) => {
            localStorage.removeItem("booking_state");
            state.currentBooking = {
                _id: "",
                main: {
                    activeFrequency: '',
                    activeRooms: [''],
                    date: '',
                    cleaning_date: tomorrow,
                    cleaning_time: new Date().toLocaleTimeString('ru-RU').split(':').slice(0,-1).join(':'),
                    one_time: false
                },
                rooms: {},
            }
        },
        selectedOneTime: (state, { payload }: PayloadAction<boolean>) => {
            state.currentBooking.main.one_time = payload
        }
    },
    extraReducers: {
        [fetchBookings.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBookings.fulfilled.type]: (state, { payload }: PayloadAction<[]>) => {
            state.isLoading = false
            state.data = payload
        },
        [fetchBookings.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false
            state.error = payload
        },
        [createBooking.pending.type]: (state) => {
            state.isLoading = true
        },
        [createBooking.fulfilled.type]: (state, { payload }: PayloadAction<Booking>) => {
            state.isLoading = false
            state.data = [...state.data, payload]
            state.error = ''
        },
        [createBooking.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false
            state.error = payload
        },
        [deleteBooking.pending.type]: (state) => {
            state.isLoading = true
        },
        [deleteBooking.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false
            state.data = state.data.filter((Booking) => Booking._id !== payload)
            state.error = ''
        },
        [deleteBooking.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false
            state.error = payload
        },
        [updateBookingAsync.pending.type]: (state) => {
            state.isLoading = true
        },
        [updateBookingAsync.fulfilled.type]: (state, { payload }: PayloadAction<Booking>) => {
            state.isLoading = false
            state.error = ''
            state.data = state.data.map((item) => {
                if (item._id === payload._id) {
                    item.main = payload.main
                }
                return item
            })
        },
        [updateBookingAsync.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false
            state.error = payload
        }
    }
})

export const { updateCurrentBooking, clearCurrentBooking, selectedOneTime, changeRooms, getLocalState } = bookingsSlice.actions
export default bookingsSlice.reducer