import React from 'react'
import { TopContentInner, TopContentText, TopContentTitle, TopContentWrapper } from './topContentStyled'

export const TopContent = ({ title = 'Title', text = '' }) => {
    return (
        <TopContentWrapper>
            <TopContentInner>
                <TopContentTitle>{title}</TopContentTitle>
                <TopContentText>{text}</TopContentText>
            </TopContentInner>
        </TopContentWrapper>
    )
}
