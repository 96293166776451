import styled from "styled-components";

export const WelcomeWrapper = styled.div`
  flex: 1;
`;
export const WelcomeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  z-index: 900;
  padding: 100px 20px;

  @media only screen and (min-width: 768px) {
    &:last-child {
      flex-direction: row;
      gap: 14px;
    }
  }
`;
export const WelcomeImage = styled.div`
  width: 142px;
  margin-bottom: 17px;
`;
export const WelcomeTitle = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 10px;
`;
export const WelcomeSpan = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;

export const WelcomeSpanLogin = styled.p`
  font-size: 18px;
  line-height: 19px;
  color: black;
  a {
    color: #1a7eda;
  }
`;
export const WelcomeBackgroundAnimate = styled.div`
  position: absolute;
  width: 100%;
  height: 0;
  background-color: #1a7eda;
  z-index: 500;
  animation: animate 2s;
  @keyframes animate {
    0% {
      height: 100%;
    }
    100% {
      height: 0%;
    }
  }
`;
