import React, { useEffect } from 'react';
import { Header, ProgressBar } from './components'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components';
import { LoginPage, BasicInfoPage, BookingsPage, WelcomePage, RegistrationPage, CleaningFormPage, ManageAreaPage } from './pages';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { userLoginTokenAction } from './redux/userReducer';
import { fetchBookings } from './redux/bookingsReducer';
import { initLanguageAction } from './redux/languageReducer';
import { fetchFillBase } from './redux/fillBaseUserReducer';
import { PreviewPage } from './pages/PreviewPage';




function App() {
  const { id, isLoading } = useAppSelector(({ user }) => user)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(userLoginTokenAction())
  }, [dispatch])
  useEffect(() => {
    dispatch(initLanguageAction())
  }, [dispatch])
  useEffect(() => {
      dispatch(fetchFillBase(id))
      dispatch(fetchBookings(id))

  }, [dispatch, id])

  if (isLoading) {
    return <ProgressBar />
  }
  if (!id) {
    return (
      <AppWrapper>
        <Header />
        <AppContent>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/account" element={<BasicInfoPage />} />
            <Route path="/booking" element={<BookingsPage />} />
            <Route path="/cleaning/:id/:room" element={<CleaningFormPage />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/manage" element={<ManageAreaPage />} />
            <Route path="/preview" element={<PreviewPage />} />
            <Route path="*" element={<WelcomePage />} />

            {/* <Route path="/" element={<WelcomePage />} />
            <Route path="/account" element={<BasicInfoPage />} />
            <Route path="/booking" element={<BookingsPage />} />
            <Route path="/cleaning/:id/:room" element={<CleaningFormPage />} />
            <Route path="/manage" element={<ManageAreaPage />} />
            <Route path="/manage/:id" element={<ManageAreaPage />} />
            <Route path="/preview" element={<PreviewPage />} />
            <Route path="/preview/:id" element={<PreviewPage />} />
            <Route path="*" element={<WelcomePage />} /> */}
          </Routes>
        </AppContent>
      </AppWrapper>
    )
  }

  return (
    <AppWrapper>
      <Header />
      <AppContent>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/account" element={<BasicInfoPage />} />
          <Route path="/booking" element={<BookingsPage />} />
          <Route path="/cleaning/:id/:room" element={<CleaningFormPage />} />
          <Route path="/manage" element={<ManageAreaPage />} />
          <Route path="/manage/:id" element={<ManageAreaPage />} />
          <Route path="/preview" element={<PreviewPage />} />
          <Route path="/preview/:id" element={<PreviewPage />} />
          <Route path="*" element={<WelcomePage />} />
        </Routes>
      </AppContent>
    </AppWrapper>
  );
}

export default App;


const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
const AppContent = styled.div`
  padding: 19px;
`