import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: Montserrat;
        -webkit-tap-highlight-color: transparent;
    }
    a {
        text-decoration: none;
    }
    img {
        width: 100%;
    }
    body {
        background: #E5E5E5;
    }
    input, textarea {
        font-size: 16px;
        user-select: auto;
    }
    
`;
